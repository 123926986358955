<template>
  <div class="container">
    <div class="main">
      <div class="check">
        <div class="cktitle">核心车况排查</div>
        <div class="ckinfo">
          <span>核心车况信息来自维修保养历史信息记录，其中</span>
          <img src="../../assets/mall/ms.png" alt="" />
          <span>代表未出现过维修历史，</span>
          <img src="../../assets/mall/merror.png" alt="" />
          <span>代表出现过维修历史</span>
        </div>
        <ul class="cknav">
          <li>
            <img src="../../assets/insu/1.png" alt="" />
            <div>{{ content.isState1 ? "疑似" : "非" }}火烧</div>
          </li>
          <li>
            <img src="../../assets/insu/2.png" alt="" />
            <div>{{ content.isState2 ? "疑似" : "非" }}泡水</div>
          </li>
          <li>
            <img src="../../assets/insu/3.png" alt="" />
            <div>{{ content.isState3 ? "疑似" : "非" }}盗抢</div>
          </li>
          <li>
            <img src="../../assets/insu/4.png" alt="" />
            <div>{{ content.isState4 ? "有" : "无" }}大额理赔</div>
          </li>
        </ul>
        <ul class="ckmsg">
          <li class="ckmsgli" :class="content.isState5 ? 'errActive' : ''">
            <div><img src="../../assets/insu/5.png" alt="" /></div>
            <div class="ckmsgdel">
              <div>
                <span>骨架</span>
                <img
                  v-if="!content.isState5"
                  src="../../assets/mall/ms.png"
                  alt=""
                />
                <img v-else src="../../assets/mall/merror.png" alt="" />
              </div>
              <div>
                包括前围骨架、A柱（左右）、后侧围（左右）、底板及加强梁、车顶、后围板、裙围、车架前横梁、前纵梁（左右）、前桥、车架中横梁、中纵梁（左右）、中后桥、车架后横梁、后纵梁（左右）
              </div>
            </div>
          </li>
          <li class="ckmsgli" :class="content.isState6 ? 'errActive' : ''">
            <div><img src="../../assets/insu/6.png" alt="" /></div>
            <div class="ckmsgdel">
              <div>
                <span>发动机</span>
                <img
                  v-if="!content.isState6"
                  src="../../assets/mall/ms.png"
                  alt=""
                />
                <img v-else src="../../assets/mall/merror.png" alt="" />
              </div>
              <div>
                包括曲轴、轴瓦/连杆瓦、凸轮轴、连杆/连杆螺丝、止推片、气门油封、缸体/缸盖、油底壳
              </div>
            </div>
          </li>
          <li class="ckmsgli" :class="content.isState7 ? 'errActive' : ''">
            <div class="ckmsgdelimg">
              <img src="../../assets/insu/7.png" alt="" />
            </div>
            <div class="ckmsgdel">
              <div>
                <span>变速器</span>
                <img
                  v-if="!content.isState7"
                  src="../../assets/mall/ms.png"
                  alt=""
                />
                <img v-else src="../../assets/mall/merror.png" alt="" />
              </div>
              <div>
                一轴/一轴轴承/一轴锁止卡环、二轴/二轴轴承/二轴锁止卡环、换挡执行机构
              </div>
            </div>
          </li>
          <li class="ckmsgli" :class="content.isState8 ? 'errActive' : ''">
            <div><img src="../../assets/insu/8.png" alt="" /></div>
            <div class="ckmsgdel">
              <div>
                <span>安全带</span>
                <img
                  v-if="!content.isState8"
                  src="../../assets/mall/ms.png"
                  alt=""
                />
                <img v-else src="../../assets/mall/merror.png" alt="" />
              </div>
              <div>主副驾驶安全带、上下卧铺安全带及对应卡扣</div>
            </div>
          </li>
          <li class="ckmsgli" :class="content.isState9 ? 'errActive' : ''">
            <div><img src="../../assets/insu/9.png" alt="" /></div>
            <div class="ckmsgdel">
              <div>
                <span>安全气囊</span>
                <img
                  v-if="!content.isState9"
                  src="../../assets/mall/ms.png"
                  alt=""
                />
                <img v-else src="../../assets/mall/merror.png" alt="" />
              </div>
              <div>
                主气囊、副气囊、气帘、侧气囊、气囊电脑/气囊控制模块、气囊线束/气囊插头
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="errmsg">
        <div class="cktitle">外观损失概览</div>
        <div class="errtiptxt">
          历史损失信息来自于维修保养历史信息，而非当前代表车况有异常，信息内容仅作参考
        </div>
        <ul class="errcar" v-if="content.lossDetail">
          <li>
            <div class="carbox">
              <img class="carImg" src="../../assets/insu/10.jpg" alt="" />
              <div
                class="carl1"
                :class="errIndex === 1 ? 'err_border' : ''"
                @click="onClickAppearance(1)"
              >
                <div>{{ content.lossDetail[1].name }}</div>
                <img
                  v-if="content.lossDetail[1].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl2"
                :class="errIndex === 0 ? 'err_border' : ''"
                @click="onClickAppearance(0)"
              >
                <div>{{ content.lossDetail[0].name }}</div>
                <img
                  v-if="content.lossDetail[0].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl3"
                :class="errIndex === 2 ? 'err_border' : ''"
                @click="onClickAppearance(2)"
              >
                <div>{{ content.lossDetail[2].name }}</div>
                <img
                  v-if="content.lossDetail[2].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl4"
                :class="errIndex === 3 ? 'err_border' : ''"
                @click="onClickAppearance(3)"
              >
                <div>{{ content.lossDetail[3].name }}</div>
                <img
                  v-if="content.lossDetail[3].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl2 carl4"
                :class="errIndex === 8 ? 'err_border' : ''"
                @click="onClickAppearance(8)"
              >
                <div>{{ content.lossDetail[8].name }}</div>
                <img
                  v-if="content.lossDetail[8].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl3 carl4"
                :class="errIndex === 4 ? 'err_border' : ''"
                @click="onClickAppearance(4)"
              >
                <div>{{ content.lossDetail[4].name }}</div>
                <img
                  v-if="content.lossDetail[4].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl2 carl4 carl5"
                :class="errIndex === 9 ? 'err_border' : ''"
                @click="onClickAppearance(9)"
              >
                <div>{{ content.lossDetail[9].name }}</div>
                <img
                  v-if="content.lossDetail[9].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl4 carl6"
                :class="errIndex === 5 ? 'err_border' : ''"
                @click="onClickAppearance(5)"
              >
                <div>{{ content.lossDetail[5].name }}</div>
                <img
                  v-if="content.lossDetail[5].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl2 carl4 carl6"
                :class="errIndex === 7 ? 'err_border' : ''"
                @click="onClickAppearance(7)"
              >
                <div>{{ content.lossDetail[7].name }}</div>
                <img
                  v-if="content.lossDetail[7].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl3 carl4 carl6"
                :class="errIndex === 6 ? 'err_border' : ''"
                @click="onClickAppearance(6)"
              >
                <div>{{ content.lossDetail[6].name }}</div>
                <img
                  v-if="content.lossDetail[6].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
            </div>
          </li>
          <li>
            <div class="warpper_err" v-if="errIndex !== null">
              <div class="title">{{ errMsg.value }}</div>
              <!-- <div class="tips">现场还原(基于理赔记录推测)</div> -->
              <div class="solid"></div>
              <div class="text">
                碰撞程度:<span style="font-weight: bold">{{
                  errMsg.value
                }}</span>
              </div>
              <div class="text">
                碰撞速度:<span style="font-weight: bold">{{
                  errMsg.speed
                }}</span>
              </div>
              <div class="text">
                车辆损失:<span style="font-weight: bold">{{
                  errMsg.loss
                }}</span>
              </div>
            </div>

            <img v-else src="../../assets/insu/11.png" alt="" />
          </li>
        </ul>
      </div>
      <div class="wbhistory check" v-if="content.records">
        <div class="cktitle">维保记录</div>
        <div class="wbbox">
          <div
            class="wxboxlist"
            v-for="(item, index) in content.records"
            :key="index"
          >
            <div class="wbtitle">维保记录</div>
            <ul class="wbul">
              <li>材料</li>
              <li>{{ item.material }}</li>
            </ul>
            <ul class="wbul">
              <li>维保里程</li>
              <li>{{ item.mileage }}</li>
            </ul>
            <ul class="wbul">
              <li>维保日期</li>
              <li>{{ item.date }}</li>
            </ul>
            <ul class="wbul">
              <li>维保项目详情</li>
              <li>{{ item.detail }}</li>
            </ul>
            <ul class="wbul">
              <li>维保类型</li>
              <li>{{ item.type }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 80px"></div>
    <ul class="ul-up">
      <li @click="backPage">上一步</li>
      <li @click="nextPage">下一步</li>
    </ul>
  </div>
</template>

<script>
import { riskCroGetGetInsurance } from "@/utils/http.js"; //路径注意
export default {
  data() {
    return {
      content: {},
      errMsg: {},
      errIndex: 0,
    };
  },
  created() {
    this.getInfo();
  },
  mouted() {},
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    nextPage() {
      this.$router.push("/insurance/details/v1");
    },
    async getInfo() {
      let str = localStorage.getItem("valuationInfo");
      let valuationInfo = JSON.parse(str);
      let obj = {
        plateNo: valuationInfo.PlateNo,
        vin: valuationInfo.Vin,
      };
      const res = await riskCroGetGetInsurance(obj);
      let content = res.data.content;
      content.lossDetail = JSON.parse(content.lossDetail);
      content.records = JSON.parse(content.records);
      this.content = content;
      localStorage.setItem("insuranceInfo", JSON.stringify(content));
    },
    onClickAppearance(index) {
      let content = this.content;
      // console.log(content.lossDetail[index].state,content)
      if (content.lossDetail[index].state == "true") {
        console.log(content.lossDetail[index]);
        this.errMsg = content.lossDetail[index];
        this.errIndex = index - 0;
      } else {
        this.errMsg = {};
        this.errIndex = null;
      }
    },
  },
};
</script>
<style scoped>
.container {
  background: #a0a8b6;
  padding: 20px 0;
  padding-bottom: 0px;
}
.main {
  box-sizing: border-box;
  padding: 0 10px;
}
.check,
.errmsg {
  font-size: 14px;
  background: #ffffff;
  box-sizing: border-box;
  padding: 10px;
  padding-top: 20px;
  border-radius: 4px;
}

.cktitle {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}
.ckinfo {
  line-height: 20px;
}
.ckinfo img {
  width: 12px;
  height: 12px;
  padding-bottom: 4px;
}
.cknav {
  display: flex;
  font-size: 14px;
  text-align: center;
  justify-content: space-between;
  background: #f6f7fb;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px 0;
}
.cknav img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}
.ckmsg {
  box-sizing: border-box;
}
.ckmsg > li:nth-of-type(1) {
  margin: 0;
}
.ckmsgli {
  padding: 10px;
  background: #f6f7fb;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 10px;
}
.ckmsgli > div:nth-of-type(1) {
  width: 90px;
  height: 68px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  padding: 0 10px;
}
.ckmsg img {
  width: 80px;
  height: 58px;
}
.ckmsgdel img {
  width: 12px;
  height: 12px;
  padding-bottom: 4px;
}
.ckmsgdel > div:nth-of-type(2) {
  font-size: 10px;
  color: #333333;
  margin-top: 4px;
  line-height: 12px;
}
.ckmsgdelimg img {
  width: 80px;
  height: 38px;
}
.ckmsgdel span {
  margin-right: 4px;
}
.errmsg {
  margin: 10px 0;
}
.errtiptxt {
  line-height: 20px;
}
.carImg {
  width: 120px;
  height: 250px;
}
.errcar {
  display: flex;
  padding: 10px 0;
  /* align-items: center; */
}
.errcar > li:nth-of-type(1) {
  margin-right: 10px;
  margin-left: 10px;
}
.carbox {
  position: relative;
}
.carl1 {
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  box-sizing: border-box;
  background: #ffffff;
  position: absolute;
  left: 6px;
  top: 10px;
  border-radius: 50%;
  text-align: center;
  font-size: 10px;
}
.carl1 > div:nth-of-type(1) {
  padding-top: 10px;
}
.carl1 img {
  width: 10px;
  height: 10px;
  margin-top: 4px;
}
.carl2 {
  left: 45px;
  top: 0px;
}
.carl3 {
  left: 86px;
}
.carl4 {
  top: 60px;
}
.carl5 {
  top: 114px;
}
.carl6 {
  top: 210px;
}
.wbul {
  display: flex;
  font-size: 14px;
  align-items: center;
}
.wbtitle {
  text-align: center;
  color: #2d6af6;
  margin-bottom: 10px;
}
.wxboxlist > ul:nth-of-type(1) {
  border-top: 1px solid #cccccc;
}
.wbul {
  border: 1px solid #cccccc;
  border-top: none;
}
.wbul > li {
  padding: 10px;
}
.wbul > li:nth-of-type(1) {
  width: 90px;
}
.wbul > li:nth-of-type(2) {
  flex: 1;
  border-left: 1px solid #cccccc;
  line-height: 20px;
}
.wxboxlist {
  margin-top: 10px;
}
.ul-up {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  /* border: 1px solid #b7b7b7; */
  background: #ffffff;
}
.ul-up > li {
  line-height: 40px;
}
.ul-up > li:nth-of-type(1) {
  color: #999999;
  flex: 1;
}
.ul-up > li:nth-of-type(2) {
  flex: 2;
  background: #2d6af6;
  color: #ffffff;
  border-radius: 4px;
}

.errActive {
  background: #fef7e8 !important;
}

.err_border {
  border: 1px solid green;
}
.warpper_err {
  flex: 1;
  /* height: 200px; */
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 10px;
  background: linear-gradient(180deg, #eaeef6 0%, #fdfdfe 26%, #fdfdfe 100%);
}
.warpper_err > .title {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
}
.warpper_err > .tips {
  font-size: 12px;
  color: #757575;
  margin-top: 8px;
}
.warpper_err > .solid {
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #e0e0e0;
  margin: 8px 0;
}
.warpper_err > .text {
  font-size: 13px;
  color: #333333;
  margin-top: 10px;
}
</style>
